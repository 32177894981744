import { gql } from 'urql'
import { ILogoAndAvatarVariants } from '~/core/@types/global'
import { IJobStages } from '../types'
import { JOB_STATUS_ENUM } from '../utilities/enum'
import { IJobInterviewKit } from '../../settings/interview-kits/types'
import { CustomFieldResponseItem } from '../../settings/profile-fields/types/custom-field'
import { LanguagesType } from '../../candidates/types'

export interface IMemberInfo {
  id: number
  avatarVariants?: ILogoAndAvatarVariants
  email: string
  fullName: string
  defaultColour: string
  labelRole?: string
  roles: {
    code?: string
    name?: string
  }[]
}

export interface IJobDetail {
  jobsShow: {
    id: number
    title: string
    status: JOB_STATUS_ENUM
    statusDescription: string
    remoteStatusDescription: string
    description: string
    pitch: string
    salaryFrom: number
    salaryTo: number
    currency: string
    typeOfSalary: string
    typeOfSalaryDescription?: string
    employmentType: string
    employmentTypeDescription: string
    remoteStatus: string
    recruiters: IMemberInfo[]
    teamMembers: IMemberInfo[]
    owner: IMemberInfo
    hiringManagers: IMemberInfo[]
    jobRecruiters: {
      id: string
      responsibility: string
      responsibilityDescription: string
      user: IMemberInfo
    }[]
    jobStages: IJobStages
    slug: string
    tenant: {
      slug: string
      name: string
      logoVariants?: ILogoAndAvatarVariants
      careerSiteSettings: {
        canonical_url: string
      }
      tenantReferralSettings: {
        enabling?: boolean
        referral_portal?: {
          job_only: boolean
          referral_only: boolean
          referral_job: boolean
        }
      }
    }
    jobLocations: {
      name: string
      address: string
      country: string
      state: string
      city: string
      zipCode: string
    }[]
    tags: {
      id: string
      name: string
    }[]
    department: {
      name: string
    }
    industries: {
      name: string
    }[]
    createdAt: string
    jobCategory: {
      id: string
      name: string
      description: string
    }
    jobLevel: string
    jobLevelDescription: string
    educationDescription: string
    skills: string[]
    preferences: {
      google_job_boards?: {
        published?: boolean
        published_date?: string
        unpublished_date?: string
      }
      freec_job_boards?: {
        status?: string
        published?: boolean
        published_date?: string
      }
    }
    recommendedClientContacts: {
      id: string
      firstName: string
      lastName: string
      title: string
      email: string
    }[]
    clientInvitations: {
      id: string
      email: string
      roleId: number
      fullName: string
    }[]
    clientMembers: IMemberInfo[]
    membersPendingOfJob: {
      id: string
      email: string
      roleId: number
      jobResponsibility: string
      role: { code: string }
    }[]
    jobIkits?: IJobInterviewKit[]
    jobReferable: boolean
    enablingReward: boolean
    referralRewardType: string
    rewardAmount: number
    rewardCurrency: string
    rewardGift: string
    company: {
      name: string
      id: number
    }
    customFields?: Array<CustomFieldResponseItem>
    permittedFields?: {
      remoteStatus?: {
        value?: string
        visibility_changeable?: boolean
      }
      remoteStatusDescription?: {
        value?: string
        visibility_changeable?: boolean
      }
      employmentType?: {
        value?: string
        visibility_changeable?: boolean
      }
      jobLevel?: {
        value?: string
        visibility_changeable?: boolean
      }
      typeOfSalary?: {
        value?: string
        visibility_changeable?: boolean
      }
      currency?: {
        value?: string
        visibility_changeable?: boolean
      }
      salaryFrom?: {
        value?: string
        visibility_changeable?: boolean
      }
      salaryTo?: {
        value?: string
        visibility_changeable?: boolean
      }
    }
    languages?: Array<LanguagesType>
  }
}
const QueryTenantJobDetailMini = gql<IJobDetail, {}>`
  query ($id: Int!) {
    jobsShow(id: $id) {
      id
      title
      status
      statusDescription
      description
      pitch
      preferences
      slug
      createdAt
      teamMembers {
        id
        avatarVariants
        email
        fullName
        defaultColour
        roles {
          name
        }
      }
      recruiters {
        id
        avatarVariants
        email
        fullName
        defaultColour
        roles {
          name
        }
      }
      jobRecruiters {
        id
        responsibility
        responsibilityDescription
        user {
          id
          email
          fullName
          defaultColour
          avatarVariants
          roles {
            code
          }
        }
      }
      hiringManagers {
        id
        avatarVariants
        email
        fullName
        defaultColour
        roles {
          name
        }
      }
      owner {
        id
        avatarVariants
        email
        fullName
        defaultColour
        roles {
          name
        }
      }
      jobStages {
        stageLabel
        stageTypeId
        stageGroup
        updateable
        index
        id
      }
      tenant {
        careerSiteSettings
        slug
        logoVariants
        tenantReferralSettings
        name
      }
      jobLocations {
        name
        address
        country
        state
        city
        zipCode
      }
      department {
        name
      }
      industries {
        name
      }
      jobCategory {
        id
        name
      }
      membersPendingOfJob {
        id
        email
        roleId
        jobResponsibility
        role {
          code
        }
      }
      educationDescription
      skills
      jobIkits {
        id
        name
        guideline
        jobIkitSessions {
          id
          name
          position
          jobIkitMetrics {
            id
            name
          }
          jobIkitQuestions {
            id
            content
          }
        }
        jobStages {
          id
          stageTypeId
          stageLabel
          stageGroup
          updateable
          index
        }
        stageIds
      }
      jobReferable
      enablingReward
      referralRewardType
      rewardAmount
      rewardCurrency
      rewardGift
      company {
        id
        name
      }
      headcount
      customFields
      permittedFields
      languages
      referenceId
      tags {
        id
        name
      }
    }
  }
`

export default QueryTenantJobDetailMini
